'use client';
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import useDebugStore from '~/state/debug';
import useUIStore from '~/state/ui';
import { cn } from '~/utils';

import FPSCounter from './components/FPSCounter';
import styles from './Debug.module.css';

const Debug = () => {
  const $debug = useRef(null);
  const debugStore = useDebugStore();
  const breakpoint = useUIStore((state) => state.breakpoint);
  const [numberOfColumns, updateNumberOfColumns] = useState(0);

  useEffect(() => {
    debugStore.initialize(); // Initialize the debug store to set defaults in localstorage

    localStorage.setItem('bs_tools', debugStore.getTypesString());

    // We send the message if the extension is already loaded
    window.postMessage({ bs_tools: 'connected' });

    const messageListener = (event: MessageEvent) => {
      if (event.data.bs_tools !== undefined) {
        const data = event.data.bs_tools;
        debugStore.update(data);
        if (event.data.init) {
          // We send the init message again in case it was not yet loaded
          window.postMessage({ bs_tools: 'connected' });
        }
      }
    };

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  useEffect(() => {
    if (debugStore.grid && $debug.current) {
      updateNumberOfColumns(
        parseInt(
          getComputedStyle($debug.current).getPropertyValue(
            '--grid-number-of-columns',
          ),
        ) + 2,
      );
    }
  }, [breakpoint, debugStore]);

  return createPortal(
    <>
      {debugStore.background && (
        <style>{`:root { --section-debug-background: orange }`}</style>
      )}
      {debugStore.fps && <FPSCounter />}
      {debugStore.grid ? (
        <div className={cn(styles.debug, styles.visible)} ref={$debug}>
          {Array.from(Array(numberOfColumns).keys(), (i) => i).map((a) => (
            <div className={styles.column} key={a} />
          ))}
        </div>
      ) : (
        <div className={styles.debug} ref={$debug} />
      )}
      {debugStore.filters && (
        <style>
          {
            '* { filter: none !important; box-shadow: none !important; backdrop-filter: none !important}'
          }
        </style>
      )}
      {debugStore.outlines && (
        <style>
          {'* { outline: solid 1px red !important; opacity: 1 !important; }'}
        </style>
      )}
    </>,
    document.body,
  );
};

export default Debug;
