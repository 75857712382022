'use client';
import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';
import { shallow } from 'zustand/shallow';

import useUIStore from '~/state/ui';
import parallaxSettings from '~/styles/theme/parallaxSettings.module.css';
import { createRoundedSvgMask } from '~/utils';
import ClientOnlyPortal from '~/utils/ClientOnlyPortal/ClientOnlyPortal';
import ScrollPosition from '~/utils/domEvents/ScrollPosition/ScrollPosition';
import { tickerAddOnce } from '~/utils/ticker';

const cornerRadiusSm = parallaxSettings.v_cornerRadiusSm;
const cornerRadiusMd = parallaxSettings.v_cornerRadiusMd;
const cornerRadiusMdV4 = parallaxSettings.v_cornerRadiusMdV4;

/* This component's purpose is only to provide CSS with the exact value of a column's width */
const DomUtils = () => {
  const pathname = usePathname();

  const previousScrollPosition = useRef(0);

  const previouIsScrollLocked = useRef(false);

  const [windowWidth, windowHeight, isScrollLocked, scrollPaddingTop] =
    useUIStore(
      (state) => [
        state.windowWidth,
        state.windowHeight,
        state.isScrollLocked,
        state.scrollPaddingTop,
      ],
      shallow,
    );
  useEffect(() => {
    previousScrollPosition.current = 0;
  }, [pathname]);

  useEffect(() => {
    // If Safari 15.4, do the impossible
    if (
      navigator.userAgent.includes('Safari/') &&
      navigator.userAgent.includes('/15.4')
    ) {
      document.body.classList.add('olderSafari');
    } else if (
      navigator.userAgent.includes('AppleWebKit/') &&
      !navigator.userAgent.includes('Chrome/')
    ) {
      document.body.classList.add('currentSafari');
    }
  }, []);

  const vh = (windowHeight || 0) * 0.01;

  // TODO: Is this computed style thing still relevant?
  const setComputedStyleComplete = useUIStore(
    (state) => state.setComputedStyleComplete,
  );

  const setHlsSupport = useUIStore((state) => state.setHlsSupport);

  useEffect(() => {
    const video = document.createElement('video');
    setHlsSupport(
      Boolean(
        video.canPlayType('application/vnd.apple.mpegURL') ||
          video.canPlayType('audio/mpegurl'),
      ),
    );
  }, [setHlsSupport]);

  const setPrefersReducedMotion = useUIStore(
    (state) => state.setPrefersReducedMotion,
  );

  useEffect(() => {
    const prefersReducedMotion = window.matchMedia(
      '(prefers-reduced-motion: reduce)',
    );
    setPrefersReducedMotion(prefersReducedMotion.matches);
  }, [setPrefersReducedMotion]);

  useEffect(() => {
    if (windowWidth) {
      // need to wait until the frame is rendered before other components can start measuring
      tickerAddOnce(() => {
        setComputedStyleComplete(true);
      });
    }
  }, [windowWidth, windowHeight, setComputedStyleComplete]);

  let styles = `:root {`;

  styles += `--window-width: ${windowWidth}px !important;`;

  // used to resolve issue with mobile safari not calculating the viewport height properly, causing the address bar to cover up the bottom of content
  styles += `--vh: ${vh}px;`;

  // create svg masks for sections with rounded corners
  styles += `--top-bottom-mask-md: ${createRoundedSvgMask({
    hasRoundedTop: true,
    hasRoundedBottom: true,
    cornerRadius: cornerRadiusMd,
  })};`;

  styles += `--top-mask-md: ${createRoundedSvgMask({
    hasRoundedTop: true,
    hasRoundedBottom: false,
    cornerRadius: cornerRadiusMd,
  })};`;

  styles += `--bottom-mask-md: ${createRoundedSvgMask({
    hasRoundedTop: false,
    hasRoundedBottom: true,
    cornerRadius: cornerRadiusMd,
  })};`;
  styles += `--top-bottom-mask-md-v4: ${createRoundedSvgMask({
    hasRoundedTop: true,
    hasRoundedBottom: true,
    cornerRadius: cornerRadiusMdV4,
  })};`;

  styles += `--top-mask-md-v4: ${createRoundedSvgMask({
    hasRoundedTop: true,
    hasRoundedBottom: false,
    cornerRadius: cornerRadiusMdV4,
  })};`;

  styles += `--bottom-mask-md-v4: ${createRoundedSvgMask({
    hasRoundedTop: false,
    hasRoundedBottom: true,
    cornerRadius: cornerRadiusMdV4,
  })};`;

  styles += `--top-bottom-mask-sm: ${createRoundedSvgMask({
    hasRoundedTop: true,
    hasRoundedBottom: true,
    cornerRadius: cornerRadiusSm,
  })};`;

  styles += `--top-mask-sm: ${createRoundedSvgMask({
    hasRoundedTop: true,
    hasRoundedBottom: false,
    cornerRadius: cornerRadiusSm,
  })};`;

  styles += `--bottom-mask-sm: ${createRoundedSvgMask({
    hasRoundedTop: false,
    hasRoundedBottom: true,
    cornerRadius: cornerRadiusSm,
  })};`;

  styles += `--top-bottom-mask-20px: ${createRoundedSvgMask({
    hasRoundedTop: true,
    hasRoundedBottom: true,
    cornerRadius: '20px',
  })};`;

  styles += `--top-bottom-mask-10px: ${createRoundedSvgMask({
    hasRoundedTop: true,
    hasRoundedBottom: true,
    cornerRadius: '10px',
  })};`;

  styles += `--top-bottom-mask-5px: ${createRoundedSvgMask({
    hasRoundedTop: true,
    hasRoundedBottom: true,
    cornerRadius: '5px',
  })};`;

  styles += `--top-bottom-mask-3px: ${createRoundedSvgMask({
    hasRoundedTop: true,
    hasRoundedBottom: true,
    cornerRadius: '3px',
  })};`;

  // adds scroll locking and padding to top of page when clicking an anchor link
  styles += `--scroll-padding-top: ${scrollPaddingTop}px`;

  styles += `}`;

  function lockScroll() {
    previousScrollPosition.current = ScrollPosition.y || 0;
    document.body.style.top = `-${previousScrollPosition.current}px`;
    document.documentElement.classList.add('noSmoothScroll');
    document.body.classList.add('noScroll');
  }

  function unlockScroll() {
    document.body.style.top = '0';
    document.body.classList.remove('noScroll');
    // scroll back to prev position without smooth scroll
    window.scrollTo({ top: previousScrollPosition.current });
    document.documentElement.classList.remove('noSmoothScroll');
  }

  useEffect(() => {
    if (isScrollLocked !== previouIsScrollLocked.current) {
      if (isScrollLocked) {
        lockScroll();
      } else {
        unlockScroll();
      }

      return () => {
        unlockScroll();
      };
    }
    previouIsScrollLocked.current = isScrollLocked;
  }, [isScrollLocked]);

  return (
    <>
      <style>{styles}</style>
      <ClientOnlyPortal selector="body">
        {/* Elements used by the WindowSize singleton to watch the window height and width respectively */}
        <div id="vertical-sizer" />
        <div id="horizontal-sizer" />
      </ClientOnlyPortal>
    </>
  );
};

export default DomUtils;
