import { useEffect } from 'react';

import initAdobeAnalytics from '~/analytics/initAdobeAnalytics';

import AdobeAnalytics from '../scripts/AdobeAnalytics';

const AdobeAnalyticsScript = ({ firstPath = '' }) => {
  useEffect(() => {
    initAdobeAnalytics();
  }, []);

  return <AdobeAnalytics firstPath={firstPath} />;
};
export default AdobeAnalyticsScript;
