'use client';

import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import UIStore from '~/state/ui';

/**
 * The usePriorityAnimationDelay observes the UI store and informs our components if there is
 * an animation in progress that should be prioritized. This allows us prioritize animation
 * performance over things like loading additional code modules such as analytics.
 *
 * @returns Tuple with a single boolean to indicate if we have animations pending
 */
const usePriorityAnimationDelay = (): boolean => {
  // set initial value to true so that we can await for all components to be mounted and
  // have a chance to register priority animations
  const [isPending, setIsPending] = useState<boolean>(true);

  useEffect(() => {
    const current = UIStore.getState().hasPendingPriorityAnimations;
    if (current !== isPending) {
      setIsPending(current);
    }
    return UIStore.subscribe(
      (state) => [state.hasPendingPriorityAnimations],
      ([value]) => setIsPending(value),
      { equalityFn: shallow },
    );
  }, [isPending]);

  return isPending;
};

export default usePriorityAnimationDelay;
