'use client';
import React, { useEffect, useRef, useState } from 'react';

import styles from '../Debug.module.css';

const FPSCounter = () => {
  const [times, setTimes] = useState<Array<number>>([]);
  const [fps, setFPS] = useState(0);
  const raf = useRef<number | null>(null);

  useEffect(() => {
    const refreshLoop = () => {
      const now: number = performance.now();
      setTimes((prevTimes) => {
        const filteredTimes = prevTimes.filter((time) => time > now - 1000);
        return [...filteredTimes, now];
      });

      // Recursively call 'refreshLoop' to continue the loop.
      raf.current = requestAnimationFrame(refreshLoop);
    };

    raf.current = requestAnimationFrame(refreshLoop);

    // Cleanup by canceling the animation frame when the component unmounts.
    return () => {
      if (raf.current !== null) {
        cancelAnimationFrame(raf.current);
      }
    };
  }, []);

  useEffect(() => {
    setFPS(times.length);
  }, [times]);

  return <div className={styles.fpsCounter}>FPS: {Math.round(fps)}</div>;
};

export default FPSCounter;
