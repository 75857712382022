'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';

import getBaseTrackingData from '~/analytics/getBaseTrackingData';
import trackEventAdobeAnalytics from '~/analytics/trackEventAdobeAnalytics';
import trackEventSegment from '~/analytics/trackEventSegment';
import { useScrollProgress } from '~/utils';
import useQuerySelector from '~/utils/useQuerySelector';

const defaultScrollDepth: { [key: number]: boolean } = {
  25: false,
  50: false,
  75: false,
  100: false,
};

/**
 * This hook tracks the user's scroll progress on a page and fires
 * tracking events at specific scroll depths (25%, 50%, 75%, 100%).
 *
 * @returns
 */
const useTrackScrollDepth = () => {
  const pathname = usePathname();
  const body = useQuerySelector('body');
  const sentEvents = useRef<typeof defaultScrollDepth>({
    ...defaultScrollDepth,
  });

  const onEnterProgress = (progress: number) => {
    const scrollPercent = progress * 100;

    for (const scrollStep of Object.keys(sentEvents.current).map(Number)) {
      if (
        !sentEvents.current[scrollStep] &&
        (scrollPercent >= scrollStep ||
          // Use 99% as a practical "100%" check
          (scrollStep === 100 && scrollPercent > 99))
      ) {
        sentEvents.current[scrollStep] = true;

        trackEventAdobeAnalytics(`scrolled-down-${scrollStep}`);
        trackEventSegment(`scrolled-down-${scrollStep}`, {
          ...getBaseTrackingData(),
        });
      }
    }
  };

  useScrollProgress(body, onEnterProgress, {
    finishOnMiddleOfScreen: true,
  });

  useEffect(() => {
    // Reset scrollDepth on page change
    sentEvents.current = { ...defaultScrollDepth };
  }, [pathname]);

  return null;
};

export default useTrackScrollDepth;
