'use client';

import {
  addHooksToOneTrustButtons,
  configureSegmentOt,
  loadSegment,
  processQueue,
} from '@frameio/segment-ot';
import Script from 'next/script';

import trackEventSegment from '~/analytics/trackEventSegment';

import { ALWAYS_OFF_INTEGRATIONS, CORE_DESTINATIONS } from './Segment.types';

async function onLoad() {
  configureSegmentOt({
    track: trackEventSegment,
    alwaysOffIntegrations: ALWAYS_OFF_INTEGRATIONS,
    analytics: window.analytics,
    coreDestinations: CORE_DESTINATIONS,
    groupRestrictions: [],
    segmentWriteKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  });

  await window.adobePrivacy.loadOneTrust();
  loadSegment();
  processQueue();
  addHooksToOneTrustButtons();
}

const Segment = () => {
  // prevent resetting segment on re-render
  if (typeof window !== 'undefined' && window?.analytics) {
    return null;
  }

  return (
    <>
      <Script
        id="manage-cookies-script"
        dangerouslySetInnerHTML={{
          __html: `!function(){window.fedsConfig = window.fedsConfig || {}; window.fedsConfig.privacy
          = window.fedsConfig.privacy || {};
          window.fedsConfig.privacy.otDomainId =
          '${process.env.NEXT_PUBLIC_ANALYTICS_DOMAIN_ID}';
          window.fedsConfig.privacy.footerLinkSelector = '#manage-cookies';}();`,
        }}
      />

      <Script
        id="privacy-script"
        src="https://www.adobe.com/etc.clientlibs/globalnav/clientlibs/base/privacy-standalone.js"
        strategy="lazyOnload"
        onLoad={onLoad}
      ></Script>
    </>
  );
};

export default Segment;
