import { create } from 'zustand';

type UpdateProps = {
  [key: string]: {
    value: string | boolean | number;
  };
};

type Toggle = boolean | null;

type DataValues = {
  background: Toggle;
  debug: Toggle;
  filters: Toggle;
  fps: Toggle;
  grid: Toggle;
  outlines: Toggle;
  glowCanvas: Toggle;
};

const values: { [key: string]: { type: string; label: string } } = {
  background: {
    type: 'boolean',
    label: 'Toggle white background for all page sections',
  },
  debug: { type: 'boolean', label: 'Toggle debug view' },
  filters: { type: 'boolean', label: 'Toggle shadows and blurs (filters)' },
  fps: { type: 'boolean', label: 'Toggle frames per second display' },
  grid: { type: 'boolean', label: 'Toggle grid' },
  outlines: { type: 'boolean', label: 'Toggle all elements outlines' },
  glowCanvas: {
    type: 'boolean',
    label: 'Display/hide the glows offscreen canvas',
  },
};

type DebugStore = DataValues & {
  update: (data: UpdateProps) => void;
  getTypes: () => { [key: string]: { type: string; label: string } };
  getTypesString: () => string;
  getValues: () => DataValues;
  getValuesString: () => string;
  initialize: () => void;
};

const useDebugStore = create<DebugStore>((set, get) => ({
  background: false,
  debug: false,
  filters: false,
  fps: false,
  grid: false,
  outlines: false,
  glowCanvas: false,
  getTypes: () => values,
  getTypesString: () => JSON.stringify(values),
  getValues: () => {
    const currentState = get();

    const returnValue: DataValues = {} as DataValues;

    for (const key of Object.keys(currentState)) {
      if (Object.keys(values).includes(key)) {
        returnValue[key as keyof DataValues] =
          currentState[key as keyof DataValues];
      }
    }
    return returnValue;
  },
  getValuesString: () => JSON.stringify(get().getValues()),
  update: (data: UpdateProps) => {
    const formattedData: { [key: string]: string | boolean | number } = {};

    for (const key in data) {
      if (
        Object.keys(get())
          .filter((key) => Object.keys(values).includes(key))
          .includes(key)
      ) {
        formattedData[key] = data[key].value;
      }
    }
    set(formattedData as DataValues);
  },
  initialize: () => {
    const storedValues: DataValues = {} as DataValues;

    for (const key in values) {
      const storedValue = localStorage.getItem(key);
      const parsedValue = storedValue ? JSON.parse(storedValue) : false;
      if (parsedValue) {
        storedValues[key as keyof DataValues] = parsedValue;
      } else {
        storedValues[key as keyof DataValues] = false;
      }
    }

    set(storedValues);
  },
}));

export default useDebugStore;
