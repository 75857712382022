import { create } from 'zustand';

import { TubeAssets } from './Tube.types';

const tubeAssetsStore = create<{
  assets: TubeAssets | null;
  setAssets: (assets: TubeAssets) => void;
}>((set) => ({
  assets: null,
  setAssets: (assets: TubeAssets) => set({ assets }),
}));

export default tubeAssetsStore;
