import { useEffect, useRef } from 'react';

const useQuerySelector = (selector: string) => {
  const el = useRef<HTMLElement | null>(null);
  useEffect(() => {
    el.current = document.querySelector(selector);
  }, [selector]);
  return el;
};

export default useQuerySelector;
