/**
 * Initialize configuration variables
 * This needs to be reset each re-render or it will not track page load properly.
 */

const initAdobeAnalytics = () => {
  window.marketingtech = {
    adobe: {
      launch: {
        url: process.env.NEXT_PUBLIC_ADOBE_ANALYTICS_LAUNCH_URL,
        controlPageLoad: true,
      },
      alloy: {
        edgeConfigId: process.env.NEXT_PUBLIC_ADOBE_ANALYTICS_EDGE_CONFIG_ID,
      },
      target: false,
      audienceManager: true,
    },
  };

  /**
   * Initialize alloy variables,
   * We do not want these to overwrite themselves if already present.
   */

  if (!window.alloy_load) {
    window.alloy_load = window.alloy_load || {};
    window.alloy_load.data = window.alloy_load.data || {};
  }

  if (!window.alloy_all) {
    window.alloy_all = window.alloy_all || {};
    window.alloy_all.data = window.alloy_all.data || {};
    window.alloy_all.data._adobe_corpnew =
      window.alloy_all.data._adobe_corpnew || {};
    window.alloy_all.data._adobe_corpnew.digitalData =
      window.alloy_all.data._adobe_corpnew.digitalData || {};
    window.alloy_all.data._adobe_corpnew.digitalData.page =
      window.alloy_all.data._adobe_corpnew.digitalData.page || {};
    window.alloy_all.data._adobe_corpnew.digitalData.page.pageInfo =
      window.alloy_all.data._adobe_corpnew.digitalData.page.pageInfo || {};
    window.alloy_all.data._adobe_corpnew.digitalData.page.pageInfo.language =
      'en-US';
  }
};

export default initAdobeAnalytics;
