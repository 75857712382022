'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import trackPageChangeAdobeAnalytics from '~/analytics/trackPageChangeAdobeAnalytics';
import trackPageChangeSegment from '~/analytics/trackPageChangeSegment';

/**
 * Tracks changes to page views
 *
 * @returns
 */
const useTrackPathname = () => {
  const pathname = usePathname();

  useEffect(() => {
    trackPageChangeAdobeAnalytics(pathname);
    trackPageChangeSegment(pathname);
  }, [pathname]);

  return null;
};

export default useTrackPathname;
