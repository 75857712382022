'use client';

import { usePathname } from 'next/navigation';
import { useRef } from 'react';

import useTrackPathname from '~/analytics/hooks/useTrackPathname';
import useTrackScrollDepth from '~/analytics/hooks/useTrackScrollDepth';
import usePriorityAnimationDelay from '~/utils/usePriorityAnimationDelay';

import ScriptLoader from './scripts/ScriptLoader';

const Analytics = () => {
  // Delay segment and AA loading by a few seconds  to help with FCP
  const isAnimationPending = usePriorityAnimationDelay();
  // pathname triggers re-renders, which is OK for tracking pathname but consequentially re-renders scripts
  const pathname = usePathname();
  // store the first pathname value and only pass that to avoid re-rendering scriptloader
  const firstPath = useRef(pathname);
  // track scrolling of page
  useTrackScrollDepth();
  // track page changes
  useTrackPathname();

  if (!isAnimationPending) {
    return (
      <>
        <ScriptLoader firstPath={firstPath.current || undefined} />
      </>
    );
  }
  return null;
};

export default Analytics;
