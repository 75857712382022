import getAnalyticsSlugFromPath from './getAnalyticsSlugFromPath';

const trackPageChangeAdobeAnalytics = (
  pathname?: string,
  isFirstTrack = false,
) => {
  if (window._satellite) {
    // first track comes from a browser refresh and needs to be type 'page load'
    const trackEventType = isFirstTrack ? 'page load' : 'state';

    const alloyDigitalData =
      window?.alloy_all?.data?._adobe_corpnew?.digitalData;
    const pageSlug = `frame.io:${getAnalyticsSlugFromPath(pathname, ':')}`;

    if (alloyDigitalData) {
      alloyDigitalData.page = {
        pageInfo: {
          customPageName: pageSlug,
        },
      };

      window._satellite.track(trackEventType, {
        xdm: {},
        data: {
          _adobe_corpnew: {
            digitalData: {
              pageSlug,
            },
          },
        },
      });
    }
  }
};

export default trackPageChangeAdobeAnalytics;
