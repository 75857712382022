'use client';

import { memo } from 'react';

import AdobeAnalyticsScript from './AdobeAnalyticsScript';
import SegmentScript from './SegmentScript';

const ScriptLoader = ({ firstPath = '' }) => {
  const isAdobeAnalyticsEnabled =
    process.env.NEXT_PUBLIC_ADOBE_ANALYTICS_LAUNCH_URL &&
    process.env.NEXT_PUBLIC_ADOBE_ANALYTICS_EDGE_CONFIG_ID;
  const isSegmentEnabled = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

  return (
    <>
      {isSegmentEnabled && <SegmentScript />}
      {isAdobeAnalyticsEnabled && (
        <AdobeAnalyticsScript firstPath={firstPath} />
      )}
    </>
  );
};

// memoize ScriptLoader so it only re-renders on prop changes
const MemoizedScriptLoader = memo(ScriptLoader);

export default MemoizedScriptLoader;
