/**
 * Generate a page slug from the current page path.
 */

export default function getAnalyticsSlugFromPath(
  pathname?: string,
  separator: string = '-',
): string {
  const path = (pathname || window?.location?.pathname || '')
    .split('/')
    .filter((x) => x !== '')
    .join(separator);

  return path || '';
}
