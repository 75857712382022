import { create } from 'zustand';

import { DeviceWrapperImages } from './DeviceWrapper.types';

const deviceWrapperStore = create<{
  deviceWrappers: DeviceWrapperImages | null;
  setDeviceWrappers: (deviceWrappers: DeviceWrapperImages) => void;
}>((set) => ({
  deviceWrappers: null,
  setDeviceWrappers: (deviceWrappers: DeviceWrapperImages) =>
    set({ deviceWrappers }),
}));

export default deviceWrapperStore;
