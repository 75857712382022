'use client';
import variantStore from '~/analytics/Variant.store';
import deviceWrapperStore from '~/components/atoms/DeviceWrapper/DeviceWrapper.store';
import shadowStore from '~/components/molecules/Shadow/Shadow.store';
import tubeAssetsStore from '~/components/molecules/Tube/Tube.store';
import stickyBackgroundStore from '~/components/organisms/modules/StickyBackground/StickyBackground.store';
import Dictionary from '~/data/stores/Dictionary';

import { ClientHydrationProps } from './ClientHydration.types';

/**
 * This component is used only on the client in order to feed the global data to components
 * that need it but that are wrapped in client components, making it impossible to fetch
 * from them directly.
 * @param shadows The images necessary to create the shadows
 * @param deviceWrappers The images necessary to create the device wrappers
 * @param dictionary The dictionary store
 * @param stickyBackground The background image for the sticky background component
 * @returns Null
 */
const ClientHydration = ({
  shadows,
  deviceWrappers,
  dictionary,
  stickyBackground,
  tubeAssets,
  variantName,
}: ClientHydrationProps) => {
  if (variantName) {
    variantStore.getState().setVariantName(variantName);
  }

  if (shadows) {
    shadowStore.getState().setShadows(shadows);
  }

  if (deviceWrappers) {
    deviceWrapperStore.getState().setDeviceWrappers(deviceWrappers);
  }

  if (dictionary) {
    Dictionary.initialize(dictionary);
  }

  if (stickyBackground) {
    stickyBackgroundStore.getState().setBackground(stickyBackground);
  }

  if (tubeAssets) {
    tubeAssetsStore.getState().setAssets(tubeAssets);
  }

  return null;
};

export default ClientHydration;
