/**
 * global window definitions use declarative merging to automatically combine
 * https://www.typescriptlang.org/docs/handbook/declaration-merging.html
 */

declare global {
  interface Window {
    adobePrivacy: {
      loadOneTrust: () => void;
      showPreferenceCenter: () => void;
    };
    analytics?: SegmentAnalytics.AnalyticsJS & {
      options: {
        integrations: {
          [integrationName: string]: boolean;
        };
      };
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fedsConfig?: any;
  }
}

// Integrations that should always be loaded
export const CORE_DESTINATIONS = { Drift: true };

// Integrations we never want to run
export const ALWAYS_OFF_INTEGRATIONS = ['Intercom', 'Appcues'];
