import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/FrameGothic.woff2\",\"weight\":\"400 600\",\"style\":\"normal\"}],\"preload\":true,\"variable\":\"--font-main\",\"display\":\"block\"}],\"variableName\":\"FrameGothic\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/NeueMachina-InktrapRegular.woff\",\"weight\":\"400\",\"style\":\"normal\"}],\"preload\":true,\"variable\":\"--font-secondary\",\"display\":\"block\"}],\"variableName\":\"NeueMachinaInktrap\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/app/ClientHydration/ClientHydration.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/components/atoms/Analytics/Analytics.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/components/atoms/Debug/Debug.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/components/atoms/DomUtils/DomUtils.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/styles/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/utils/useIsInView/useIsInView.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/utils/useIsomorphicLayoutEffect.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/utils/useScrollProgress/useScrollProgress.ts")