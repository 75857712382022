'use client';

import Script from 'next/script';

import trackPageChangeAdobeAnalytics from '~/analytics/trackPageChangeAdobeAnalytics';

const AdobeAnalytics = ({ firstPath }: { firstPath: string }) => {
  return (
    <Script
      src="https://www.adobe.com/marketingtech/main.standard.min.js"
      strategy="lazyOnload"
      onLoad={() => trackPageChangeAdobeAnalytics(firstPath, true)}
    ></Script>
  );
};

export default AdobeAnalytics;
