import { create } from 'zustand';

import { StickyBackgroundImage } from './StickyBackground.types';

const stickyBackgroundStore = create<{
  stickyBackgroundImage: StickyBackgroundImage | null;
  setBackground: (stickyBackgroundImage: StickyBackgroundImage) => void;
}>((set) => ({
  stickyBackgroundImage: null,
  setBackground: (stickyBackgroundImage: StickyBackgroundImage) =>
    set({ stickyBackgroundImage }),
}));

export default stickyBackgroundStore;
